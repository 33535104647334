import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue, Watch, } from 'vue-property-decorator';
import { LoginService, ModifyPasswordService } from '@/sevices';
var FormType;
(function (FormType) {
    FormType["Valid"] = "valid";
    FormType["Update"] = "update";
})(FormType || (FormType = {}));
let ModifyPassword = class ModifyPassword extends Vue {
    constructor() {
        super(...arguments);
        this.formType = FormType.Valid;
        this.oldPassword = '';
        this.rsaKey = {};
        this.passRuleData = {};
        this.loading = false;
    }
    get baseUserId() {
        let publicUserInfo = localStorage.getItem('publicUserInfo') || '';
        try {
            publicUserInfo = JSON.parse(publicUserInfo);
            return publicUserInfo?.baseUserId || '';
        }
        catch (error) {
            return '';
        }
    }
    get userName() {
        let publicUserInfo = localStorage.getItem('publicUserInfo') || '';
        try {
            publicUserInfo = JSON.parse(publicUserInfo) || {};
            const user = publicUserInfo || {};
            return user.username || '';
        }
        catch (error) {
            return '';
        }
    }
    get btnTxt() {
        return this.formType === FormType.Valid ? '下一步' : '确认';
    }
    visibleChange() {
        this.resetForm();
    }
    validingEvent(loading) {
        this.loading = loading;
    }
    /**
     * 确认回调
     */
    async handleOk() {
        const res = await this.getKey();
        if (!res)
            return;
        this.$refs[this.formType].submit();
    }
    async validHandler(oldPassword) {
        try {
            const res = await ModifyPasswordService.getPasswordRuleInfo();
            if (res.success && res.data.passwordRuleStatus) {
                this.formType = FormType.Update;
                this.oldPassword = oldPassword;
                this.passRuleData = res.data;
            }
            else {
                this.formType = FormType.Update;
                this.oldPassword = oldPassword;
            }
        }
        catch (error) {
            this.formType = FormType.Update;
            this.oldPassword = oldPassword;
        }
    }
    updateHandler() {
        this.$emit('update:isVisible', false);
        localStorage.clear();
        this.$router.push({
            name: 'login',
            params: { type: 'user' },
        });
    }
    /**
     * 重置表单
     */
    resetForm() {
        Object.assign(this.$data, this.$options.data.call(this));
    }
    /**
     * 获取加密公钥
     */
    async getKey() {
        if (this.rsaKey.index)
            return true;
        const res = await LoginService.getKey();
        const { success, data, errMessage = '服务异常！' } = res;
        if (!success || !data.index || !data.key) {
            this.$message.warning(errMessage);
            this.rsaKey = {};
            return false;
        }
        this.rsaKey = data;
        return true;
    }
};
__decorate([
    PropSync('isVisible', { default: false })
], ModifyPassword.prototype, "visible", void 0);
__decorate([
    Prop({ default: () => ({}) })
], ModifyPassword.prototype, "option", void 0);
__decorate([
    Watch('visible')
], ModifyPassword.prototype, "visibleChange", null);
ModifyPassword = __decorate([
    Component({
        name: 'ModifyPassword',
        components: {
            Valid: () => import('./valid'),
            Update: () => import('./update'),
        },
    })
], ModifyPassword);
export default ModifyPassword;
